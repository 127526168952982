import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Box, Themed } from "theme-ui"

const ArxiuDePagines = ({ data }) => {

  return (
    <Layout>
      {data.allWpPage.nodes.map((node, i) => (
        <Box key={i}>
          <Themed.h2><Themed.a as={Link} to={`/${node.slug}`}>{node.title}</Themed.a></Themed.h2>
          <Themed.p>{node.date}</Themed.p>
          <Box
            sx={{
              variant: 'styles',
            }}
            dangerouslySetInnerHTML={{ __html: node.excerpt}}
          />
        </Box>
      ))}
    </Layout>
  )

}

export default ArxiuDePagines

export const pageQuery = graphql`
  query ArxiuDePagines {
    allWpPage(sort: { fields: date }) {
      nodes {
        title
        slug
      }
    }
  }
`
